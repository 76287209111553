:root {
    --header-height: 3rem;
  
    /*========== Colors ==========*/
    --hue: 174;
    --sat: 63%;
    --first-color: hsl(var(--hue), var(--sat), 40%);
    --first-color-alt: hsl(var(--hue), var(--sat), 36%);
    --title-color: hsl(var(--hue), 12%, 15%);
    --text-color: hsl(var(--hue), 8%, 35%);
    --body-color: hsl(var(--hue), 100%, 99%);
    --container-color: #FFF;
  
    /*========== Font and typography ==========*/
    --body-font: 'Open Sans', sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;
    --tiny-font-size: .625rem;
  
    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
  }
  
.nav__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -1px 12px hsla(174, 63%, 15%, 0.15);
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 1.25rem 1.25rem 0 0;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
  }

.nav__menu .nav__list,
.nav__menu .nav__link {
  display: flex;
}

.nav__menu .nav__link {
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  color: var(--title-color);
  font-weight: 600;
}

.nav__menu .nav__list {
  justify-content: space-around;
}

.nav__menu .nav__name {
  font-size: var(--tiny-font-size);
  /* display: none;*/ /* Minimalist design, hidden labels */
}

.nav__menu .nav__icon {
  font-size: 1.5rem;
}

/*Active link*/
.nav__menu .active-link {
  position: relative;
  color: var(--first-color);
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
}

/* Cho iOS */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-min-device-pixel-ratio : 3) {
  body {
      padding-top: env(safe-area-inset-top);
  }
}

/* Cho các thiết bị khác */
@media only screen and (max-width : 480px) {
  body {
      padding-top: 20px;
  }
}